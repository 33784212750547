<template>
  <div class="fondo-maestro-principal">
    <v-row class="pt-10">
        <v-col cols="12" class="text-h5 titulo-card my-3">
          <h2 style="color:#008f87; text-align:center;">
            Lee Lee Teacher Edition
          </h2>
          <!-- <h4>
            Lee Lee Teacher Edition
          </h4> -->
        </v-col>
        <v-col cols="12" md="8" sm="10" lg="6" offset-lg="3" offset-md="2" offset-sm="1" class="segundo-cuadro" v-if="typeUser == 'full'">
          <v-card color="grey lighten-3" dark href="/individualTeacher/activitieslist" class="pa-md-4 pa-lg-5" elevation="2">
            <v-row>
              <v-col cols="10" md="9" lg="8">
                <div class="titulo-card">
                  <h2>TEACHING TOOLS</h2>
                  <p>Teaching tools</p>
                </div>
              </v-col>
              <v-col cols="2" md="3" lg="4">
                <v-btn width="100" height="100" color="rgba(1,162,165,1)" dark absolute top fab>
                  <img src="../../../assets/admin/teaching-tools.svg" class="pa-5" style="width:100px;">
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" sm="10" lg="6" offset-lg="3" offset-md="2" offset-sm="1" class="tercer-cuadro">
          <v-card color="grey lighten-3" dark to="/individualTeacher/resources" class="pa-md-4 pa-lg-5" elevation="2">
            <v-row>
              <v-col cols="10" md="9" lg="8">
                <div class="titulo-card">
                  <h2>RESOURCES</h2>
                  <p>Dowload your resources</p>
                </div>
              </v-col>
              <v-col cols="2" md="3" lg="4">
                <v-btn width="100" height="100" color="rgba(1,162,165,1)" dark absolute top fab>
                  <v-icon size="50">mdi-file-cog</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" sm="10" lg="6" offset-lg="3" offset-md="2" offset-sm="1" class="text-center" v-if="typeUser == 'free'">
          <v-btn depressed color="teal darken-3" to="/select-plan" x-large outlined>
            Upgrade To Pro 
            <v-icon
              dark
              large
              right
            >
              mdi-arrow-up-bold-circle
            </v-icon>
          </v-btn>
        </v-col>
    </v-row>
    <div class="btn-inferior" v-if="inferior">
      <!-- <v-chip>
        Press here to Open App
      </v-chip> -->
      <div class="box">
        Open download
      </div>
      <!-- <v-chip class="my-2">
        <v-icon left>mdi-numeric-2-circle</v-icon>
        Open APP 
      </v-chip> -->
      <v-icon size="55" color="rgba(220,15,0,1)">
        mdi-arrow-down-bold
      </v-icon>
    </div>
    <div class="btn-superior" v-if="superior">
      <v-icon size="55" color="rgba(220,15,0,1)">
        mdi-arrow-up-bold
      </v-icon>
      <div class="box">
        Open the App  after download
      </div>
      <!-- <v-chip>
        <v-icon left>mdi-numeric-1-circle</v-icon>
        Download
      </v-chip>
      <v-chip class="my-2">
        <v-icon left>mdi-numeric-2-circle</v-icon>
        Open APP 
      </v-chip> -->
    </div>
    <v-dialog v-model="dialogDownload" width="700">
      <v-card>
        
        <v-toolbar color="rgba(1,162,165,1)">
          <v-toolbar-title>START IN CLASE LEE LEE TEACHER EDITION</v-toolbar-title>
        </v-toolbar>
        <v-container>
        <v-row>
          <v-col cols="6">
            <v-chip>
              <v-icon left>mdi-numeric-1-circle</v-icon>
              WAIT FOR DOWNLOADING 
            </v-chip>
          </v-col>
          <v-col cols="1">
            <v-icon large left>mdi-arrow-right-bold</v-icon>
          </v-col>
          <v-col cols="4">
            <v-chip>
              <v-icon left>mdi-numeric-2-circle</v-icon>
              OPEN APP 
            </v-chip>
          </v-col>
        </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDownload = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFisrtTime" transition="dialog-top-transition" width="800">
       <v-card>
            <v-card-text class="pb-0">
              <div class="text-h3 pa-8 pb-0 text-center">Welcome {{ nameUser}} to Leelee Teacher Edition</div>
            </v-card-text>
             <v-card-text>
               <v-row>
                 
                 <v-col cols="8" class="my-auto">
                  <div class="text-h5 pa-8">For change your password  click in "settings" <v-icon large color="orange darken-2">mdi-arrow-right-bold</v-icon></div>
                  
                 </v-col>
                 <v-col cols="4" class="pt-1 pb-0">
                   <img src="../../../assets/admin/menu-lateral.png" class="pa-5" style="width:100px;">
                 </v-col>
                 <v-col cols="3" offset-sm="4">
                  <v-btn depressed large color="teal" @click="dialogFisrtTime = false" dark>Continue</v-btn>
                 </v-col>
               </v-row>
            </v-card-text>

          </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth ,db } from '../../../plugins/firebase'
import firebase from 'firebase'
export default {
	name: "ActivitiesList",
  data() {
      return {
        nameUser:'',
        user: '',
        inferior: false,
        superior: false,
        dialogDownload : false,
        dialogFisrtTime: false,
        typeUser: ''
      }
    },
    created () {
      
      var agente = window.navigator.userAgent;
      var navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];
      for(var i in navegadores){
        if(agente.indexOf( navegadores[i]) != -1 ){
          //return navegadores[i];
          console.log(navegadores[i])
          console.log(navigator.userAgent.search("Safari"))
          console.log(navigator.userAgent.search("Chrome"))
        }
      }  
      //console.log(userExists)
      // if (userExists == null) {
      //   this.logout();
      // }
    },
    methods: {
      searchNavigator () {
        var agente = window.navigator.userAgent;
        var navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];
        for(var i in navegadores){
          if(agente.indexOf( navegadores[i]) != -1 ){
            return navegadores[i];
            // console.log(navegadores[i])
            // console.log(navigator.userAgent.search("Safari"))
            // console.log(navigator.userAgent.search("Chrome"))
          }
        }  
      },
      async logout () {
        await auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        location.href = "/login";
      },
      
      
      async verifyUser (){
        const userExists = await auth().currentUser;
        console.log(userExists)
      },
      async GetDataTeacher() {
        let iud = this.$store.state.user.uid;
        
        let info = await db.collection("IndividualTeacher").doc(iud).get();
        console.log(info.data())
        this.nameUser = info.data().Name;
        let fisrtTime = info.data().FirstTime;
        // if (info.data().free) {
        //   this.typeUser = 'free'
        // }
        if (info.data().Full) {
          //console.log(info.data().full)
          this.typeUser = 'full';
        }else{
          this.typeUser = 'free';
        }
        if (fisrtTime) {
          this.dialogFisrtTime = true;
          db.collection("IndividualTeacher").doc(iud).update({
            FirstTime: false
          })
        }
      },
    },
    mounted() {
      const demoClasses = document.querySelectorAll('.fondo-maestro-principal');
      console.log(demoClasses[0]);
      var height = window.innerHeight;
      demoClasses[0].style.height=height+'px';
      this.GetDataTeacher();
      //this.verifyUser();
    }
}
</script>

<style scoped>
  .fondo-maestro-principal{
    background-image: url('../../../assets/admin/fondo-misiones.gif');
    background-attachment: fixed, scroll;
    background-position: center;
    background-repeat: no-repeat, repeat-y;
    background-size: cover;
  }
  .titulo-card h2{
    font-size: 1.7em;
    font-weight: 800;
    color: #01a2a5;
    text-align: right;
  }
  .titulo-card p{
    font-size: 15px;
    color: #6f7070;
    text-align: right;
  }
  .download {
    text-align: center;
    font-size: 1.2em;
    padding: 5px;
    color: #c76262;
  }
.btn-inferior {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 5px; /* Borde del boton */
	letter-spacing: 0.5px; /* Espacio entre letras */
	background-color: #6363639e; /* Color de fondo */
	padding: 18px 30px; /* Relleno del boton */
	position: fixed;
  margin-left: 20px;
	bottom: 40px;
	left: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: 13;
  width: 210px;
  text-align: center;
}
/* .btn-inferior:hover {
	background-color: #f1f1f1;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} */
@media only screen and (max-width: 600px) {
 	.btn-inferior {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		left: 20px;
	}
  .titulo-card h2{
    font-size: 0.8em;
    font-weight: 600;
    
  }
}
@media only screen and (max-width: 992px) {
  .titulo-card h2{
    font-size: 1.3em;
    font-weight: 600;
    
  }
}
@media only screen and (max-width: 1200px) {
  .titulo-card h2{
    font-size: 1.5em;
    font-weight: 700;
    
  }
}
@keyframes example {
  0% {
    background-color: #6363639e;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: #dbdada; /* Color de fondo al pasar el cursor */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
  }
}
.btn-superior {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 5px; /* Borde del boton */
	letter-spacing: 0.5px; /* Espacio entre letras */
	background-color: #6363639e; /* Color de fondo */
	padding: 18px 30px; /* Relleno del boton */
	position: fixed;
  margin-right: 20px;
	top: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
  animation-name: example2;
  animation-duration: 2s;
  animation-iteration-count: 13;
  width: 210px;
  text-align: center;
}
/* .btn-superior:hover {
	background-color: #f1f1f1; 
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} */
@media only screen and (max-width: 600px) {
 	.btn-superior {
		font-size: 14px;
		padding: 12px 20px;
		top: 20px;
		right: 20px;
	}
}
@keyframes example2 {
  0% {
    background-color: #6363639e;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: #dbdada; /* Color de fondo al pasar el cursor */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
  }
}
.box {
  border-radius: 25px;
  padding: 5px;
  width: 150px;
  background: white;
  color: black;
}
.primer-cuadro{
  margin-top: 50px;
  margin-bottom: 15px;
}
.segundo-cuadro{
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}
.tercer-cuadro{
  margin-top: 15px;
}
@media (max-height: 600px) {
  .primer-cuadro{
    margin-top: 10px;
  }
}
</style>